import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link, PageProps } from "gatsby";
import { Seo } from "../../components/seo";
import NextPageButton from "../../components/next-page-button";
import { StaticImage } from "gatsby-plugin-image";

const Start1 = ({ location }: PageProps): React.ReactElement => (
  <Chakra.Box width="100%">
    <Seo title="Start" location={location} />
    <Chakra.Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Chakra.Flex
        maxWidth="908px"
        py="40px"
        px="40px"
        justifyContent="space-between"
        alignItems={{ base: "start", lg: "center" }}
        height="100%"
        m="auto"
        width="100%"
        position="relative"
      >
        <Chakra.Box maxWidth="354px">
          <Chakra.Text
            as="h4"
            pb="30px"
            fontSize="28px"
            letterSpacing="0.22px"
            lineHeight="20px"
            fontWeight="600"
          >
            Current Situation
          </Chakra.Text>
          <Chakra.VStack
            textAlign="left"
            alignItems="start"
            justifyContent="start"
            spacing="20px"
            fontSize="14px"
            letterSpacing="0.11px"
            lineHeight="20px"
            fontWeight="300"
          >
            <p>
              Designing new products, services and experiences in many
              organisations is very difficult. It can take a long time, requires
              big investments and is heavily governed and regulated.
            </p>
            <p>
              New innovations and projects delivered using this traditional
              delivery model have a very low chance of success. This model is
              high-risk, with many projects failing to meet business case
              expectations.
            </p>
          </Chakra.VStack>
        </Chakra.Box>
        <Chakra.Box display={{ base: "none", lg: "unset" }}>
          <StaticImage
            src="../../images/start-slides/start-slide-1.png"
            alt=""
          />
        </Chakra.Box>
      </Chakra.Flex>
    </Chakra.Flex>
    <NextPageButton as={Link} to="/start/2">
      Next
    </NextPageButton>
  </Chakra.Box>
);

export default Start1;
